<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="isLoadingContent">
          <v-skeleton-loader type="image" />
        </template>

        <template v-else>
          <v-img
            alt="Article Image"
            position="center"
            max-height="440"
            :src="informationHookContent.value.thumbnail"
          />
        </template>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="12">
        <InfoArticleDetails />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InformationMixins from '@/modules/information/mixins/InformationMixins';
import InfoArticleDetails from '@/modules/information/features/InfoArticleDetails';

export default {
  name: 'InformationDetailsPage',
  components: { InfoArticleDetails },
  mixins: [InformationMixins],
  created() {
    this.loadInformationContent(this.$route.params.id);
  },
};
</script>
