<template>
  <span class="info-article-caption">
    <template v-if="date">{{ date | dateFormat('MMMM DD, YYYY') }}</template>
    <template v-if="author && date"> | </template>
    <template v-if="author">
      {{ $t('information.by', { author: author }) }}
    </template>
  </span>
</template>

<script>
export default {
  name: 'InfoArticleCaption',
  props: {
    loading: Boolean,
    date: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.info-article-caption {
  @include font-size(16, 150);
  color: var(--v-grey7-base);
  margin-bottom: 10px;
}
</style>
