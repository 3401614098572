<template>
  <v-container class="carousel px-0">
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="product-title size24-weight500">
          {{ $t('information.related') }}
        </h2>
        <carousel
          :scrollPerPage="$vuetify.breakpoint.lgAndUp"
          :perPage="carouselSlideSizeForItem"
          :navigationEnabled="displayNavigationArrows"
          :paginationEnabled="false"
          :touchDrag="true"
          :mouseDrag="false"
          :navigationNextLabel="navigationNext"
          :navigationPrevLabel="navigationPrev"
        >
          <slide
            class="product-slide px-md-3 px-1"
            v-for="(item, index) in relatedProducts"
            :key="index"
          >
            <a :href="item.resource | convertLink" class="product-card">
              <v-img
                :alt="item.title"
                class="product-card-image"
                :src="item.thumbnail ? item.thumbnail : placeholder"
                data-cy="carousel-image"
                position="center center"
                aspect-ratio="1.5"
              />
              <h3 class="product-card-title size16-weight600">
                {{ item.title }}
              </h3>
              <template v-if="item.priceValue">
                <p class="product-card-price size14-weight400">
                  {{ $t('common.from') }}
                  {{ item.priceValue | currency(item.priceCurrency) }}
                  {{ $t('common.per_person') }}
                </p>
              </template>
            </a>
          </slide>
        </carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'InfoLandingProductCarousel',
  props: {
    relatedProducts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      placeholder: require('@/assets/placeholder-thumbnail.png'),
      navigationNext:
        '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="circle1" opacity="0.8" cx="24" cy="24" r="24" fill="white"/><path d="M23 30L29 24L23 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      navigationPrev:
        '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="circle1" opacity="0.8" cx="24" cy="24" r="24" fill="white"/><path d="M25 30L19 24L25 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    };
  },

  components: {
    Carousel,
    Slide,
  },

  computed: {
    displayNavigationArrows() {
      return (
        this.$vuetify.breakpoint.lgAndUp &&
        this.relatedProducts &&
        this.relatedProducts.length > this.carouselSlideSizeForItem
      );
    },

    carouselSlideSizeForItem() {
      let pageSize = 4;
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.relatedProducts.length > 3 ? (pageSize = 3.1) : (pageSize = 3);
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        this.relatedProducts.length > 2 ? (pageSize = 2.1) : (pageSize = 2);
      }
      return pageSize;
    },
  },

  filters: {
    convertLink(val) {
      return `${window.location.origin}/${val}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  &-title {
    color: var(--v-grey8-base);
    margin-bottom: 26px;
    @include font-size(24, 150, 500);
  }

  &-card {
    cursor: pointer;
    text-decoration: none;

    &-title {
      color: var(--v-grey8-base);
      margin-top: 16px;
      margin-bottom: 6px;
      @include font-size(16, 150, 600);
    }

    &-image {
      height: 232px;
      width: 100%;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        height: 168px;
      }

      &-results {
        height: 150px;
      }

      ::v-deep {
        .v-image__image {
          border-radius: 8px;
        }
      }
    }

    &-price {
      color: var(--v-grey8-base);
    }
  }

  &-slide {
    white-space: initial;
    max-width: 25%;

    @media (max-width: map-get($grid-breakpoints, md)) {
      max-width: 35%;
      box-sizing: content-box;

      &:first-child {
        padding-left: 24px !important;
      }
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      max-width: 50%;
      box-sizing: content-box;

      &:first-child {
        padding-left: 24px !important;
      }
    }
  }
}

::v-deep {
  & .VueCarousel-navigation-button:focus {
    outline: none !important;
  }

  & .VueCarousel-navigation-next {
    top: 92px;
    transform: translateX(-30%) !important;
  }

  & .VueCarousel-navigation-prev {
    top: 92px;
    transform: translateX(30%) !important;
  }

  & .VueCarousel-inner {
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-right: 100px;
    }
  }

  & .VueCarousel-wrapper {
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: calc(100% + 24px);
    }
  }

  & .VueCarousel {
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-right: -12px;
      margin-left: -24px;
    }
  }

  & #circle1:hover {
    cursor: pointer;
    opacity: 1.5;
  }
}
</style>
