<template>
  <v-container class="pt-5 info-article-details-container">
    <v-row no-gutters>
      <v-col cols="12">
        <FlightAlert ref="FlightAlert" isDetailsPage :link-text="linkText" />
      </v-col>
    </v-row>

    <v-row class="pt-5">
      <v-col cols="12" class="py-0 px-md-0">
        <template v-if="isLoadingContent">
          <v-skeleton-loader width="200" type="text" />
        </template>

        <template v-else-if="!informationCategoriesError">
          <InfoArticleCaption
            :date="informationHookContent.value.publishDate"
            :author="informationHookContent.value.author"
          />
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="px-md-0">
        <template v-if="isLoadingContent">
          <v-skeleton-loader type="text" />
        </template>

        <template v-else>
          <vue-markdown
            class="info-article-title"
            :source="informationHookContent.value.title"
          />
        </template>
      </v-col>

      <v-col cols="12" class="py-0 px-md-0">
        <template v-if="isLoadingContent">
          <v-skeleton-loader type="sentences" />
        </template>

        <template v-else>
          <vue-markdown
            class="info-article-excerpt"
            :source="informationHookContent.value.short_description"
          />
        </template>
      </v-col>

      <v-col cols="12" class="px-md-0">
        <template v-if="isLoadingContent">
          <v-skeleton-loader type="paragraph" />
        </template>

        <template v-else>
          <vue-markdown
            class="info-article-details"
            :source="informationHookContent.value.content"
          />
        </template>
      </v-col>
      <v-col cols="12" class="px-md-0">
        <template v-if="isLoadingContent">
          <v-skeleton-loader type="text" />
        </template>

        <template v-else-if="relatedTags">
          <InfoArticleRelatedTopics
            :related-topics-tags="informationHookContent.value.tags"
          />
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-0 px-md-0">
        <template v-if="relatedArticles && relatedArticles.length">
          <InfoLandingArticlesCarousel :relatedArticles="relatedArticles" />
        </template>
        <template v-if="productId && productId.length">
          <InfoLandingProductCarousel :relatedProducts="productId" />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InfoArticleCaption from '@/modules/information/features/InfoArticleCaption';
import InformationMixins from '@/modules/information/mixins/InformationMixins';
import InfoArticleRelatedTopics from '@/modules/information/features/InfoArticleRelatedTopics';
import InfoLandingProductCarousel from '@/modules/information/features/infoLanding/InfoLandingProductCarousel';
import InfoLandingArticlesCarousel from '@/modules/information/features/infoLanding/InfoLandingArticlesCarousel';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import FlightAlert from '@/core/components/FlightAlert';

export default {
  name: 'InfoArticleDetails',
  mixins: [InformationMixins, SelectedContextFlightMixins],
  components: {
    InfoArticleRelatedTopics,
    InfoArticleCaption,
    InfoLandingArticlesCarousel,
    InfoLandingProductCarousel,
    FlightAlert,
  },

  computed: {
    productId() {
      if (
        !!this.informationHookContent &&
        !!this.informationHookContent.value
      ) {
        return this.informationHookContent.value.linked_products;
      } else {
        return [];
      }
    },

    relatedArticles() {
      if (
        !!this.informationHookContent &&
        !!this.informationHookContent.value
      ) {
        return this.informationHookContent.value.linked_articles;
      } else {
        return null;
      }
    },

    relatedTags() {
      if (
        !!this.informationHookContent &&
        !!this.informationHookContent.value
      ) {
        return this.informationHookContent.value.tags;
      } else {
        return null;
      }
    },

    linkText() {
      return this.$t('information.experience_search', {
        destinationCity: `${this.selectedContextFlight?.destination.city}`,
      });
    },
  },

  watch: {
    selectedContextFlight(val, oldVal) {
      if (val?.destination.city !== oldVal?.destination.city) {
        this.$refs.FlightAlert.showAlert('information-landing');
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.info-article-details-container {
  max-width: 840px;

  .info-article-title {
    margin-bottom: 0;
    @include font-size(36, 150);
    color: #0a0e14;
  }

  .info-article-excerpt {
    margin-bottom: 48px;
    @include font-size(20, 120);
    color: #595959;
  }

  .info-article-details {
    @include font-size(16, 150);
    color: var(--v-grey8-base);

    h3 {
      margin-bottom: 20px;
    }

    a {
      text-decoration: none;
      color: var(--v-primary-base);
    }
  }
}

// styles for article embed items, which are received from informationHookContent.value.content
::v-deep {
  & .embed-item {
    height: 168px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      height: auto;
    }

    &__link {
      color: inherit !important;
      text-decoration: none !important;
    }

    &__wrapper {
      display: flex !important;
      height: 100% !important;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        flex-direction: column !important;
      }
    }

    &__img-wrapper {
      max-width: 128px;
      border-radius: 8px;
      overflow: hidden;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        width: 70px;
        height: 70px;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }

    &__rating-icon {
      width: 16px;
    }

    &__rating-text {
      color: #88888c;
    }
  }
}
</style>
